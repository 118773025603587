import { EditorReadyFn, EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID, SANTA_MEMBERS_APP_ID } from '../../constants';
import { log, monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';
import { startSequentialPromises, stopSequentialPromises } from '../../enforceSequentiality';
import { registerToComponentAddedToStageEvent } from '../../wrappers/components';
import { registerAlwaysAvailableApps } from './integration';
import { addLoginMenus, addMembersSubMenu } from './services/menus';
import { addMyAccountMenuItemToMembersAreaMenus } from './services/menu-items';
import { hideProfileCard, refreshMemberPage } from './services/members-area-page';
import {
  shouldEnableSignUpPrivacyNoteType,
  shouldHideProfileCardForNonSocialSites,
  shouldRemoveGlobalControllerIfItExists,
  shouldRemoveMembersAreaV2UnsuccessfulInstall,
} from '../../../utils/experiments';
import { addGlobalController, removeGlobalControllerIfExists } from './services/controllers';
import { verifyMembersAreaInstallation } from './services/installation-verification';
import { uninstallMembersArea } from './services/uninstall';

const addMembersAreaPage = (editorSDK: EditorSDK) => {
  return editorSDK.document.application.add(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    managingAppDefId: SANTA_MEMBERS_APP_ID,
    shouldNavigate: true,
    isSilent: true,
  });
};

const maybeHideProfileCard = async (editorSDK: EditorSDK) => {
  if (await shouldHideProfileCardForNonSocialSites()) {
    return hideProfileCard(editorSDK);
  }
};

async function setSignUpPrivacyNoteType(editorSDK: EditorSDK) {
  try {
    if (await shouldEnableSignUpPrivacyNoteType()) {
      editorSDK.siteMembers.setPrivacyNoteType(APP_TOKEN, 'NOTE');
    }
  } catch {
    log('Failed to set privacyNoteType');
  }
}

const installMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  try {
    await addGlobalController(editorSDK);
    await Promise.all([addMembersSubMenu(editorSDK), addLoginMenus(editorSDK, options), addMembersAreaPage(editorSDK)]);
    await Promise.all([addMyAccountMenuItemToMembersAreaMenus(editorSDK, options), maybeHideProfileCard(editorSDK)]);

    const shouldVerify = await shouldRemoveMembersAreaV2UnsuccessfulInstall();

    if (shouldVerify) {
      const installationState = await verifyMembersAreaInstallation(editorSDK);

      if (!installationState.success) {
        throw installationState.error;
      }
    }

    await refreshMemberPage(editorSDK);
  } catch (e) {
    await uninstallMembersArea(editorSDK);
    throw e;
  }
};

export const editorReady: EditorReadyFn = async (editorSDK, _, options) => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  try {
    const editorReadyAction = async () => {
      if (options.firstInstall) {
        const shouldRemoveGlobalController = await shouldRemoveGlobalControllerIfItExists();

        if (shouldRemoveGlobalController) {
          await removeGlobalControllerIfExists(editorSDK);
        }

        await monitoredTransaction('v2.install', () => installMembersArea(editorSDK, options));
        await setSignUpPrivacyNoteType(editorSDK);
      }

      await registerToComponentAddedToStageEvent(editorSDK);
      await registerAlwaysAvailableApps(editorSDK);

      const shouldVerify = !options.firstInstall && (await shouldRemoveMembersAreaV2UnsuccessfulInstall());

      if (shouldVerify) {
        const installationState = await verifyMembersAreaInstallation(editorSDK);

        if (!installationState.success) {
          log(`Members Area v2.editorReady validation: ${installationState.error}`);
        }
      }

      startSequentialPromises();
    };

    await toMonitored('v2.editorReady', editorReadyAction);
  } catch (error: any) {
    console.error('Members Area V2 editorReady failed');
    console.error(error);
    stopSequentialPromises(error);

    throw error;
  }
};

import { EditorSDK } from '@wix/platform-editor-sdk';
import { WIX_BLOG } from '@wix/app-definition-ids';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import { createBIService } from '../../../../utils/bi';
import { RouteConfiguration } from '../../types';
import { installSiteApplications } from '../../../platform-api/addApplications';
import { getIsADI, getIsBlogWriterProfilesOnly, getVerticalsApps } from '../../../services/applicationState';
import { addApplicationsToSlots, refreshMemberPage } from '../services/members-area-page';
import { getProfileType, isProfileRegisteredOnlyByBlog, setProfileType } from '../services/blog-writer';
import { groupDefinitionsByMethod } from './definitions';
import { ProfileType } from '../../../../types/blog-writer';
import { addMenuItemsToMembersAreaMenus } from '../services/menu-items';
import { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';
import { getGlobalMembersAreaController } from '../../../wrappers/controllers';
import { maybeShowProfileCard } from '../services/layout';

const filterOutInstalledWidgets = (toInstallWidget: IntegrationApplication[], installedWidgets: RouteConfiguration[]) =>
  toInstallWidget.filter(({ widgetId }) => !installedWidgets.some((route) => route.widgetId === widgetId));

export const installApps = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
  shouldNavigate: boolean = false,
) => {
  const { widgetPlugins, siteApps } = groupDefinitionsByMethod(definitions);
  const { routes } = await getGlobalMembersAreaController(editorSDK);
  const notInstalledWidgets = routes ? filterOutInstalledWidgets(widgetPlugins, routes) : widgetPlugins;

  if (notInstalledWidgets.length > 0) {
    await addApplicationsToSlots(editorSDK, notInstalledWidgets, shouldNavigate);
    await addMenuItemsToMembersAreaMenus(editorSDK, notInstalledWidgets);
    await refreshMemberPage(editorSDK);
  }

  if (siteApps.length > 0) {
    await installSiteApplications({ editorSDK, applications: siteApps, shouldNavigate: false });
  }
};

const maybeSetBlogWriterProfileType = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const hasBlogTriggeredInstall = verticalAppDefId === WIX_BLOG;
  if (!hasBlogTriggeredInstall || getIsADI() || !(await isProfileRegisteredOnlyByBlog(editorSDK))) {
    return;
  }

  const profileType = getIsBlogWriterProfilesOnly() ? ProfileType.BWP_ONLY : ProfileType.BWP_ALL;
  const currentProfileType = await getProfileType(editorSDK);

  if (profileType !== currentProfileType) {
    await setProfileType(editorSDK, profileType);
  }
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter((app) => {
    return app.shouldInstallInitially !== false;
  });

  if (integrationAppsToInstall.length > 0) {
    await installApps(editorSDK, integrationAppsToInstall);
    await maybeShowProfileCard(editorSDK, integrationAppsToInstall);
  }

  await maybeSetBlogWriterProfileType(editorSDK, verticalAppDefId);
  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};

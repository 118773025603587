import { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';
import { monitoredTransactionFactory } from '../../../utils/monitoring';

import { APP_TOKEN } from '../../constants';
import { getPublicApplications } from './services/members-area-page';
import { navigateToMembersAreaPage } from './services/navigation';
import { MembersAreaPrivateApi } from '../types';
import { uninstallMembersArea } from './services/uninstall';

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  return {
    hasSocialPages: async () => {
      const action = async () => {
        await navigateToMembersAreaPage(editorSDK);
        const installedPublicApps = await getPublicApplications(editorSDK);
        return installedPublicApps.length > 0;
      };

      return monitoredTransaction('private-api.v2.hasSocialPages', action);
    },
    uninstall: async () => {
      const action = async () => {
        await uninstallMembersArea(editorSDK);
        await editorSDK.history.add(APP_TOKEN, { label: 'Deleting Members Area' });
      };

      return monitoredTransaction('v2.uninstall', action);
    },
    // TODO: Implement with custom pages
    removePage: (pageData: { id: string }) => {
      throw new Error('[MAV2] PrivateAPI.removePage is not implemented');
    },
    /** @deprecated */
    updatePageData: ({ pageRef, pageData }: { pageRef: PageRef; pageData: PageData }) => {
      throw new Error('[MAV2] PrivateAPI.updatePageData is not implemented');
    },
    /** @deprecated */
    getAllRouters: () => {
      throw new Error('[MAV2] PrivateAPI.getAllRouters is not implemented');
    },
    /** @deprecated */
    getRouterDataByPage: (pageRef: PageRef) => {
      throw new Error('[MAV2] PrivateAPI.getRouterDataByPage is not implemented');
    },
    /** @deprecated */
    updatePageDataInRouter: (pageRef: PageRef, dataFieldToUpdate: string, updatedData: string | object) => {
      throw new Error('[MAV2] PrivateAPI.updatePageDataInRouter is not implemented');
    },
    /** @deprecated */
    saveInnerRoute: (newInnerRoute: string, pageRef: PageRef, pageRouterData: { innerRoute: string }) => {
      throw new Error('[MAV2] PrivateAPI.saveInnerRoute is not implemented');
    },
    /** @deprecated */
    savePasswordPermissions: (pageRef: PageRef, privacy: string, plainPassword: string) => {
      throw new Error('[MAV2] PrivateAPI.savePasswordPermissions is not implemented');
    },
    /** @deprecated */
    renamePage: (pageName: string, pageRef: PageRef, pageRouterData: { innerRoute: string }) => {
      throw new Error('[MAV2] PrivateAPI.renamePage is not implemented');
    },
  };
};
